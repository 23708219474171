<template>
  <case-view v-bind:dataTypeName="'aragDataType'" v-bind:insurance="'ARAG'" v-bind:permission="'PruefungAnstellungsvertrag'" v-bind:rebindPermission="'PruefungAnstellungsvertrag'" v-bind:dataType="'PruefungAnstellungsvertrag'" v-bind:name="'Prüfung Anstellungsvertrag'" v-bind:controllerURL="controllerURL" />
</template>

<script>
import CaseView from "@/components/Case/CaseView";
import {getApiURL} from "@/configBuilder";

export default {
  name: "PruefungAnstellungsvertragView",
  components: { CaseView },

  data: () => ({
    controllerURL: getApiURL("AragData")
  }),
  methods: {},
  computed: {
  }
}
</script>

<style scoped>

</style>
